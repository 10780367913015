import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"

const rel1 = "\vtext {drift(%)} = 1.74";
const rel2 = "\vtext {drift(%)} = 2.70";
const rel3 = "b \cdot h \cdot t = 450x450x75 mm";
const rel4 = "L = 900,1350 mm";
const rel5 = "\vrho_{l}(%) = 1.07, 1.77";
const rel6 = "\vrho_{t}(%) = 0.13, 0.25";
const rel7 = "f_{c}' = 23.2÷35 MPa";
const rel8 = "f_{y} = 550 MPa";
const rel9 = "f_{yh} = 550 MPa";
const rel10 = "P/(f_{ce}' A_{g}) = 0.06÷0.21";

const rel11 = "\vtext {drift(%)} = 0.83";
const rel12 = "b \cdot h \cdot t = 600x400x100 mm";
const rel13 = "L = 900,1350 mm";
const rel14 = "\vrho_{l}(%) = 0.88";
const rel15 = "\vrho_{t}(%) = 0.12";
const rel16 = "f_{c}' = 17 MPa";
const rel17 = "f_{y} = 540 MPa";
const rel18 = "f_{yh} = 655 MPa";
const rel19 = "P/(f_{ce}' A_{g}) = 0.05";
const rel20 = "M/(VH) = 1.50÷3.75";

const rel21 = "\vtext {drift(%)} = 1.84";
const rel22 = "\vtext {drift(%)} = 3.07";
const rel23 = "\vtext {drift(%)} = 5.7";

const rel24 = "\vtext {drift(%)} = 0.21";
const rel25 = "b x h = 900x450 mm";
const rel26 = "L = 1400 mm";
const rel27 = "\vrho_{l} = 0.016";
const rel28 = "f_{c}' = 28 MPa";
const rel29 = "f_{y} = 560 MPa";
const rel30 = "f_{yh} = 450 MPa";
const rel31 = "P/(f_{ce}' A_{g}) = 0.08";

const rel32 = "\vtext {drift(%)} = 0.93";
const rel33 = "\vtext {drift(%)} = 1.43";
const rel34 = "\vtext {drift(%)} = 2.14";

const rel35 = "A: \vtext {drift(%)} = 0.43";
const rel36 = "b \cdot h \cdot t = 750x750x120 mm";
const rel37 = "L = 3200 mm";
const rel38 = "\vrho_{l} = 0.0155";
const rel39 = "f_{c}' = 30 MPa";
const rel40 = "f_{y} = 335 MPa";
const rel41 = "f_{yh} = 320 MPa";
const rel42 = "P/(f_{ce}' A_{g}) = 0.1,0.5,0.3,0.3 \vtext { (for A,B,C,D)}";
const rel43 = "A: A_{sw}/s = \vPhi6/60 mm";
const rel44 = "B: A_{sw}/s = \vPhi6/30 mm";
const rel45 = "C: A_{sw}/s = \vPhi6/40 mm";

const rel46 = "B: \vtext {drift(%)} = 0.34";
const rel47 = "C+D: \vtext {drift(%)} = 0.41";

const rel48 = "A: \vtext {drift(%)} = 3.44";
const rel49 = "B: \vtext {drift(%)} = 1.13";
const rel50 = "C: \vtext {drift(%)} = 3.25";
const rel51 = "D: \vtext {drift(%)} = 3.44";

const rel52 = "\vtext {drift(%)} = 6.2, 4.33, 1.97";
const rel53 = "b \cdot h \cdot t = 1.5 x 1.5 x 0.3m";
const rel54 = "L = 6.5, 4.5, 3.5m";
const rel55 = "\vrho_{l} = 0.02";
const rel55b = "\vrho_{w} = 0.011, 0.0044, 0.0026";
const rel56 = "f_{c}' = 33 MPa";
const rel57 = "f_{y} = 439 MPa";
const rel58 = "f_{yh} = 424 MPa";
const rel59 = "P/(f_{ce}' A_{g}) = 0.082,0.078";

const rel60 = "\vDelta_{u}/L (%) = 1.5%";
const rel61 = "\vDelta_{u}/L (%) = 1.6%";
const rel62 = "b \cdot h = 2.74 x 1.02m";
const rel63 = "L = 6.5, 14.0m";
const rel64 = "\vrho_{l} = 0.004, 0.007";
const rel65 = "\vrho_{w} = 0.0009";
const rel66 = "f_{c}' = 35 MPa";
const rel67 = "f_{y} = 500 MPa";
const rel68 = "f_{yh} = 500 MPa";
const rel69 = "P/(f_{ce}' A_{g}) = 0.09";

const rel70 = "\vtext {drift(%)} = 0.63";
const rel71 = "b \cdot h = 506 x 360mm";
const rel72 = "L = 1240mm";
const rel73 = "\vrho_{l} = 0.014 - 0.021";
const rel74 = "\vrho_{w} = 0.035";
const rel75 = "f_{c}' = 42.6 MPa";
const rel76 = "f_{y} = 385 MPa";
const rel77 = "f_{yh} = 385 MPa";
const rel78 = "P/(f_{ce}' A_{g}) = 0.1, 0.2";

const rel79 = "\vtext {drift(%)} = 2.75";

const rel80 = "\vtext {drift(%)} = 4.7";
const rel81 = "b \cdot h \cdot t = 500 x 360 x 100mm";
const rel82 = "L = 1240mm";
const rel83 = "\vrho_{l} = 0.014";
const rel84 = "\vrho_{w} = 0.0072";
const rel85 = "f_{c}' = 38.7 MPa";
const rel86 = "f_{y} = 335 MPa";
const rel87 = "f_{yh} = 235 MPa";
const rel88 = "P/(f_{ce}' A_{g}) = 0.12";

const rel89 = "\vtext {drift(%)} = 5.6";


const rel90 = "\vmu_{d} = 0.45";
const rel91 = "b \cdot h \cdot t = 600 x 400 x 100mm";
const rel92 = "L = 900, 1350mm";
const rel93 = "\vrho_{l}(%) = 0.88";
const rel94 = "\vrho_{s}(%) = 0.12";
const rel95 = "f_{c}' = 17 MPa";
const rel96 = "f_{y} = 540 MPa";
const rel97 = "f_{yh} = 655 MPa";
const rel98 = "P/(f_{ce}' A_{g}) = 0.05";
const rel99 = "M/(VH) = 1.50÷3.75";

const rel100 = "\vmu_{d} = 1.0";
const rel101 = "\vmu_{d} = 1.7";
const rel102 = "\vmu_{d} = 3.1";

const rel103 = "\vmu_{d} = 1";
const rel104 = "b \cdot h \cdot t = 750x750x120 mm";
const rel105 = "L = 3200 mm";
const rel106 = "\vrho_{l} = 0.0155";
const rel107 = "f_{c}' = 30 MPa";
const rel108 = "f_{y} = 335 MPa";
const rel109 = "f_{yh} = 320 MPa";
const rel110 = "P/(f_{ce}' A_{g}) = 0.1,0.5,0.3,0.3 \vtext { (for A,B,C,D)}";
const rel111 = "A: A_{sw}/s = \vPhi6/60 mm";
const rel112 = "B: A_{sw}/s = \vPhi6/30 mm";
const rel113 = "C: A_{sw}/s = \vPhi6/40 mm";

const rel114 = "A: \vmu_{d} = 8.0";
const rel115 = "B: \vmu_{d} = 3.3";
const rel116 = "C: \vmu_{d} = 8.0";
const rel117 = "D: \vmu_{d} = 6.0";

const rel118 = "\vmu_{d} = 10.8, 7.7, 3.7";
const rel119 = "b \cdot h \cdot t = 1.5 x 1.5 x 0.3m";
const rel120 = "L = 6.5, 4.5, 3.5m";
const rel121 = "\vrho_{l} = 0.02";
const rel122 = "\vrho_{w} = 0.011, 0.0044, 0.0026";
const rel123 = "f_{c}' = 33 MPa";
const rel124 = "f_{y} = 439 MPa";
const rel125 = "f_{yh} = 424 MPa";
const rel126 = "P/(f_{ce}' A_{g}) = 0.082,0.078";

const rel127 = "\vmu_{d} = 6";
const rel128 = "b \cdot h= 550 x 550mm";
const rel129 = "L/D = 2 or 4";
const rel130 = "\vrho_{l} = 0.031";
const rel131 = "f_{c}' = 30 MPa";
const rel132 = "f_{yh} = 300 MPa";
const rel133 = "P/(f_{ce}' A_{g}) = 0.3";

const rel134 = "\vmu_{d} = 2.93";
const rel135 = "b \cdot h \cdot t = 506 x 360 x 100mm";
const rel136 = "L = 1240mm";
const rel137 = "\vrho_{l} = 0.014";
const rel138 = "\vrho_{s} = 0.072";
const rel139 = "f_{c}' = 38.7 MPa";
const rel140 = "f_{y} = 335 MPa";
const rel141 = "f_{yh} = 235 MPa";
const rel142 = "P/(f_{ce}' A_{g}) = 0.12";

const rel143 = "\vmu_{d} = 3.52";















const LSExperimental = () => {
  //javascript
  const title = 'As Built Piers';
  const metatitle = 'As Built Piers';
  const description = 'Meta Description for As Built Piers';
  const metadescription = description;


  const scrollToRef = (ref) => window.scrollTo({
    left:0,
    top:ref.current.offsetTop,
    behavior: 'smooth'
  });
  
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);
  const Ref16 = useRef(null);
  const Ref17 = useRef(null);
  const Ref18 = useRef(null);
  const Ref19 = useRef(null);
  const Ref20 = useRef(null);
  const Ref21 = useRef(null);
  const Ref22 = useRef(null);
  const Ref23 = useRef(null);
  const Ref24 = useRef(null);
  const Ref25 = useRef(null);
  const Ref26 = useRef(null);
  const Ref27 = useRef(null);
  const Ref28 = useRef(null);
  
  const executeScroll = (e, ref) => {
    e.preventDefault();
    scrollToRef(ref);
  }
  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
        <li>
            <a className="more" href="../cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more" href="../rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more actclass" href="../hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      <div className="content">
        <section className="exp-section">
          <h1>Hollow Rectangular Piers</h1>
          <div className="content-table">
            <h2>Table 1: Hollow Rectangular Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref1)}>[1]</button> Calvi, G.M., Pavese, A., Rasulo, A., Bolognini, D. (2005)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel1}</MathJax.Node>, short columns<br /><MathJax.Node inline>{rel2}</MathJax.Node>, taller columns</div>
                  </MathJax.Context></td>
                  <td>Failure of transverse reinforcement accompanied by longitudinal bar buckling </td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel3}</MathJax.Node><br /><MathJax.Node inline>{rel4}</MathJax.Node><br /><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node><br /><MathJax.Node inline>{rel10}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref2)}>[2]</button> Cassese, P., Ricci, P., Verderame, G. M. (2017)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel11}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal bars</td>
                  <td rowSpan={4}>Cyclic loading</td>
                  <td rowSpan={4}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel12}</MathJax.Node><br /><MathJax.Node inline>{rel13}</MathJax.Node><br /><MathJax.Node inline>{rel14}</MathJax.Node><br /><MathJax.Node inline>{rel15}</MathJax.Node><br /><MathJax.Node inline>{rel16}</MathJax.Node><br /><MathJax.Node inline>{rel17}</MathJax.Node><br /><MathJax.Node inline>{rel18}</MathJax.Node><br /><MathJax.Node inline>{rel19}</MathJax.Node><br /><MathJax.Node inline>{rel20}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel21}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling and onset of long. bars buckling</td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel22}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Complete concrete cover spalling and severe buckling of longitudinal bars</td>
                </tr>
                <tr className="even">
                  <td>LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel23}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing of concrete at the flanges, failure in tension of longitudinal bars</td>
                </tr>
                <tr>
                  <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button> Delgado, R., Delgado, P., Vila Pouca, N., Arede, A., Rocha, P., Costa, A. (2009, shear dominated behavior</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel24}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Initiation of cracking</td>
                  <td rowSpan={4}>Cyclic loading</td>
                  <td rowSpan={4}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel25}</MathJax.Node><br /><MathJax.Node inline>{rel26}</MathJax.Node><br /><MathJax.Node inline>{rel27}</MathJax.Node><br /><MathJax.Node inline>{rel28}</MathJax.Node><br /><MathJax.Node inline>{rel29}</MathJax.Node><br /><MathJax.Node inline>{rel30}</MathJax.Node><br /><MathJax.Node inline>{rel31}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS1-LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel32}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Cracks of width 1.5 mm (design prior to EC8 establishment) and 0.9 mm (EC8 compliant design)</td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel33}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Cracks of width > 1.5 mm, spalling of concrete cover </td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel34}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended spalling of concrete cover</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref4)}>[4]</button> Mander, J. B., Priestley, M.J.N., Park, R. (1983)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel35}</MathJax.Node><br /><MathJax.Node inline>{rel46}</MathJax.Node><br /><MathJax.Node inline>{rel47}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel36}</MathJax.Node><br /><MathJax.Node inline>{rel37}</MathJax.Node><br /><MathJax.Node inline>{rel38}</MathJax.Node><br /><MathJax.Node inline>{rel39}</MathJax.Node><br /><MathJax.Node inline>{rel40}</MathJax.Node><br /><MathJax.Node inline>{rel41}</MathJax.Node><br /><MathJax.Node inline>{rel42}</MathJax.Node><br /><MathJax.Node inline>{rel43}</MathJax.Node><br /><MathJax.Node inline>{rel44}</MathJax.Node><br /><MathJax.Node inline>{rel45}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel48}</MathJax.Node><br /><MathJax.Node inline>{rel49}</MathJax.Node><br /><MathJax.Node inline>{rel50}</MathJax.Node><br /><MathJax.Node inline>{rel51}</MathJax.Node><br /></div>
                  </MathJax.Context></td>
                  <td>Extended concrete spalling/ bar bucking initiation/fracture of transverse reinforcement </td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref5)}>[5]</button> Mo, Y., Yeh, Y. K., Zhong, J., Hsu, T. T.C. (2006)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel52}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete crushing</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel53}</MathJax.Node><br /><MathJax.Node inline>{rel54}</MathJax.Node><br /><MathJax.Node inline>{rel55}</MathJax.Node><br /><MathJax.Node inline>{rel55b}</MathJax.Node><br /><MathJax.Node inline>{rel56}</MathJax.Node><br /><MathJax.Node inline>{rel57}</MathJax.Node><br /><MathJax.Node inline>{rel58}</MathJax.Node><br /><MathJax.Node inline>{rel59}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref6)}>[6]</button> Pinto, Molina, Tsionis (2003), deficient columns</td>
                  <td>LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel60}</MathJax.Node>, short pier <br /><MathJax.Node inline>{rel61}</MathJax.Node>, tall pier </div>
                  </MathJax.Context></td>
                  <td>Fracture of longitudinal reinforcement after buckling</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel62}</MathJax.Node><br /><MathJax.Node inline>{rel63}</MathJax.Node><br /><MathJax.Node inline>{rel64}</MathJax.Node><br /><MathJax.Node inline>{rel65}</MathJax.Node><br /><MathJax.Node inline>{rel66}</MathJax.Node><br /><MathJax.Node inline>{rel67}</MathJax.Node><br /><MathJax.Node inline>{rel68}</MathJax.Node><br /><MathJax.Node inline>{rel69}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref7)}>[7]</button> Qiang, Xiuli, Zhou, Lee (2013)</td>
                  <td>LS1-LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel70}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement in tension</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel71}</MathJax.Node><br /><MathJax.Node inline>{rel72}</MathJax.Node><br /><MathJax.Node inline>{rel73}</MathJax.Node><br /><MathJax.Node inline>{rel74}</MathJax.Node><br /><MathJax.Node inline>{rel75}</MathJax.Node><br /><MathJax.Node inline>{rel76}</MathJax.Node><br /><MathJax.Node inline>{rel77}</MathJax.Node><br /><MathJax.Node inline>{rel78}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel79}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement  followed by bar fracture</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref8)}>[8]</button> Zhang, Y., Harries, K.A., Yuan, W.C. (2013)</td>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel80}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel81}</MathJax.Node><br /><MathJax.Node inline>{rel82}</MathJax.Node><br /><MathJax.Node inline>{rel83}</MathJax.Node><br /><MathJax.Node inline>{rel84}</MathJax.Node><br /><MathJax.Node inline>{rel85}</MathJax.Node><br /><MathJax.Node inline>{rel86}</MathJax.Node><br /><MathJax.Node inline>{rel87}</MathJax.Node><br /><MathJax.Node inline>{rel88}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel89}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended concrete cover spalling and buckling of long. bars</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>Calvi, G.M., Pavese, A., Rasulo, A., Bolognini, D. (2005). Experimental and Numerical Studies on the Seismic Response of R.C. Hollow Bridge Piers. Bull Earthquake Eng 3, 267–297. <a href="https://doi.org/10.1007/s10518-005-2240-0">https://doi.org/10.1007/s10518-005-2240-0.</a></li>
              <li ref={Ref2}>Cassese, P., Ricci, P., Verderame, G. M. (2017). Experimental study on the seismic performance of existing reinforced concrete bridge piers with hollow rectangular section, Engineering Structures, Vol. 144, pp 88-106, <a href="http://dx.doi.org/10.1016/j.engstruct.2017.04.047">http://dx.doi.org/10.1016/j.engstruct.2017.04.047</a></li>
              <li ref={Ref3}>Delgado, R., Degado, P., Pouca, N. V., Aréde, A., Rocha, P., Costa, A. (2009). Shear effects on hollow section piers under seismic actions: experimental and numerical analysis, Bulletin of Earthquake Engineering, Vol. 7, pp 377-389, <a href="https://doi.org/10.1007/s10518-008-9098-x">https://doi.org/10.1007/s10518-008-9098-x.</a></li>
              <li ref={Ref4}>Mander, J. B., Priestley, M. J. N., Park, R. (1983). Behaviour or Ductile Hollow Reinforced Columns, Third South Pacific Regional Conference on Earthquake Engineering, Wellington, May, 1983. Bull. of the NZSEE, Vol. 16, No. 4, 273-290.</li>
              <li ref={Ref5}>Mo, Y.L., Yeh, Y. K., Zhong, J., Hsu, T. T.C. (2006). Seismic Behavior of Shear-Critical Hollow Bridge Columns, Structures Congress, May 18-21, 2006, St Louis, Missouri, United States.</li>
              <li ref={Ref6}>Pinto, A. V., Molina, J., Tsionis, G. (2003). Cyclic tests on large-scale models of existing bridge piers with rectangular hollow cross-section, Earthquake Engineering and Structural Dynamics, Vol. 32, pp.1995-2012, <a href="https://doi.org/10.1002/eqe.311">https://doi.org/10.1002/eqe.311.</a></li>
              <li ref={Ref7}>Qiang, H., Xiuli, D., Zhou, Y., Lee, G. C. (2013). Experimental study of hollow rectangular bridge column performance under vertical and cyclically bilateral loads, Earthquake Engineering and Engineering Vibration, Vol. 12, pp 433-445, <a href="https://doi.org/0.1007/s11803-013-0184-y">https://doi.org/0.1007/s11803-013-0184-y.</a></li>
              <li ref={Ref8}>Zhang, Y., Harries, K.A., Yuan, W.C. (2013). Experimental and numerical investigation of the seismic performance of hollow rectangular bridge piers constructed with and without steel fiber reinforced concrete, Engineering Structures, vol.48, pp 255-265, <a href="https://doi.org/10.1016/j.engstruct.2012.09.040">https://doi.org/10.1016/j.engstruct.2012.09.040.</a></li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <div className="content-table">
            <h2>Table 2: Hollow Rectangular Piers: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility (μ<sub>d</sub>)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref9)}>[1]</button> Cassese, P., Ricci, P., Verderame, G. M. (2017)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel90}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal bars</td>
                  <td rowSpan={4}>Cyclic loading</td>
                  <td rowSpan={4}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel91}</MathJax.Node><br /><MathJax.Node inline>{rel92}</MathJax.Node><br /><MathJax.Node inline>{rel93}</MathJax.Node><br /><MathJax.Node inline>{rel94}</MathJax.Node><br /><MathJax.Node inline>{rel95}</MathJax.Node><br /><MathJax.Node inline>{rel96}</MathJax.Node><br /><MathJax.Node inline>{rel97}</MathJax.Node><br /><MathJax.Node inline>{rel98}</MathJax.Node><br /><MathJax.Node inline>{rel99}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel100}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling and onset of long. bars buckling</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel101}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Complete concrete cover spalling and severe buckling of longitudinal bars</td>
                </tr>
                <tr>
                  <td>LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel102}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing of concrete at the flanges, failure in tension of longitudinal bars</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref10)}>[2]</button> Mander, J. B., Priestley, M.J.N., Park, R. </td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel103}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel104}</MathJax.Node><br /><MathJax.Node inline>{rel105}</MathJax.Node><br /><MathJax.Node inline>{rel106}</MathJax.Node><br /><MathJax.Node inline>{rel107}</MathJax.Node><br /><MathJax.Node inline>{rel108}</MathJax.Node><br /><MathJax.Node inline>{rel109}</MathJax.Node><br /><MathJax.Node inline>{rel110}</MathJax.Node><br /><MathJax.Node inline>{rel111}</MathJax.Node><br /><MathJax.Node inline>{rel112}</MathJax.Node><br /><MathJax.Node inline>{rel113}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel114}</MathJax.Node><br /><MathJax.Node inline>{rel115}</MathJax.Node><br /><MathJax.Node inline>{rel116}</MathJax.Node><br /><MathJax.Node inline>{rel117}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Spalling of concrete cover</td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref11)}>[3]</button> Mo, Y., Yeh, Y. K., Zhong, J., Hsu, T. T.C. (2006)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel118}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete crushing</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel119}</MathJax.Node><br /><MathJax.Node inline>{rel120}</MathJax.Node><br /><MathJax.Node inline>{rel121}</MathJax.Node><br /><MathJax.Node inline>{rel122}</MathJax.Node><br /><MathJax.Node inline>{rel123}</MathJax.Node><br /><MathJax.Node inline>{rel124}</MathJax.Node><br /><MathJax.Node inline>{rel125}</MathJax.Node><br /><MathJax.Node inline>{rel126}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref12)}>[4]</button> Priestley, M. J. N. and Park, R. (1987)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel127}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Fracture of hoops and longitudinal bar buckling</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel128}</MathJax.Node><br /><MathJax.Node inline>{rel129}</MathJax.Node><br /><MathJax.Node inline>{rel130}</MathJax.Node><br /><MathJax.Node inline>{rel131}</MathJax.Node><br /><MathJax.Node inline>{rel132}</MathJax.Node><br /><MathJax.Node inline>{rel133}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref13)}>[5]</button> Zhang, Y., Harries, K.A., Yuan, W.C. (2013)</td>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel134}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel135}</MathJax.Node><br /><MathJax.Node inline>{rel136}</MathJax.Node><br /><MathJax.Node inline>{rel137}</MathJax.Node><br /><MathJax.Node inline>{rel138}</MathJax.Node><br /><MathJax.Node inline>{rel139}</MathJax.Node><br /><MathJax.Node inline>{rel140}</MathJax.Node><br /><MathJax.Node inline>{rel141}</MathJax.Node><br /><MathJax.Node inline>{rel142}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel143}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended concrete cover spalling and buckling of long. bars</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
            <li ref={Ref9}>Cassese, P., Ricci, P., Verderame, G. M. (2017). Experimental study on the seismic performance of existing reinforced concrete bridge piers with hollow rectangular section, Engineering Structures, Vol. 144, pp 88-106, <a href="http://dx.doi.org/10.1016/j.engstruct.2017.04.047">http://dx.doi.org/10.1016/j.engstruct.2017.04.047</a></li>
            <li ref={Ref10}>Mander, J. B., Priestley, M. J. N., Park, R. (1983). Behaviour or Ductile Hollow Reinforced Columns, Third South Pacific Regional Conference on Earthquake Engineering, Wellington, May, 1983. Bull. of the NZSEE, Vol. 16, No. 4, 273-290.</li>
            <li ref={Ref11}>Mo, Y.L., Yeh, Y. K., Zhong, J., Hsu, T. T.C. (2006). Seismic Behavior of Shear-Critical Hollow Bridge Columns, Structures Congress, May 18-21, 2006, St Louis, Missouri, United States.</li>
            <li ref={Ref12}>Priestley, M. J. N. and Park, R. (1987). Strength and Ductility of Concrete Bridge Columns Under Seismic Loading, ACI Structural Journal, No. 84-S8., pp 61-76, <a href="https://doi.org/10.14359/2800">https://doi.org/10.14359/2800.</a></li>
            <li ref={Ref13}>Zhang, Y., Harries, K.A., Yuan, W.C. (2013). Experimental and numerical investigation of the seismic performance of hollow rectangular bridge piers constructed with and without steel fiber reinforced concrete, Engineering Structures, vol.48, pp 255-265, <a href="https://doi.org/10.1016/j.engstruct.2012.09.040">https://doi.org/10.1016/j.engstruct.2012.09.040.</a></li>
            </ol>
          </div>
        </section>
      </div>
      
    </Layout>
  )
}

export default LSExperimental
